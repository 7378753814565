import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractStyleAwareComponent } from 'src/app/shared/components';
import { GlobalVariableService, ThemeService } from 'src/app/shared/services';
import { AudioService } from 'src/app/shared/services/audio.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent
  extends AbstractStyleAwareComponent
  implements OnInit
{
  constructor(
    private audioService: AudioService,
    private router: Router,
    globalVariableService: GlobalVariableService,
    themeService: ThemeService
  ) {
    super(globalVariableService, themeService);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.audioService.playAudio('success');
  }

  goToHome(forceReload: boolean = false) {
    // We force a reload. Without the reload it seems the NFC reader goes into an unstable state.
    this.router.navigate(['/home', { forceReload: forceReload }], {
      replaceUrl: true,
    });
  }
}
