export const environment = {
  production: false,
  dynamicsBaseUrl: "https://dev.vcdynamics.nl/Api/CloudConnector/",
  logLevel: 3,
  dynamics: {
    url: "https://vconsyst-dev.crm4.dynamics.com"
  },
  appInsights: {
    connectionString: 'InstrumentationKey=462fbc29-9222-4f6d-a6c9-a16ee420dd16;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'
  },
  themeBaseUrl: "https://savcumdoorscandev.blob.core.windows.net/themes/"
};