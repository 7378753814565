import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";
import { GlobalVariableService } from "./global-vars.service";

@Injectable()
export class LoggingService {
  appInsights: ApplicationInsights;

  constructor(private globalVariableService: GlobalVariableService) {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights.connectionString,
        enableAutoRouteTracking: true // option to log all route changes,
      }
    });

    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    properties = this.addDefaultProperties(properties);

    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    properties = this.addDefaultProperties(properties);

    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    properties = this.addDefaultProperties(properties);

    this.appInsights.trackTrace({ message: message}, properties);
  }

  private addDefaultProperties(properties: { [key: string]: any; } | undefined) {
    if (properties) {
      properties["frontendId"] = this.globalVariableService.getFrontend()?._vc_frontendid_value;
      properties["userName"] = this.globalVariableService.getUserName();
    } else {
      properties = {
        "frontendId": this.globalVariableService.getFrontend()?._vc_frontendid_value,
        "userName": this.globalVariableService.getUserName()
      };
    }
    return properties;
  }
}