<mat-card class="bottom-card full">
    <mat-card-header>
        <mat-card-title>{{ pageInformation.Title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>
            {{ pageInformation.Description }}
         </p>
    </mat-card-content>
    <mat-card-actions>
        <button class="full" mat-flat-button color="primary" (click)="handleButton()" (contextmenu)="handleButton()" disable-longpress>{{ pageInformation.Button_Text }}</button>
    </mat-card-actions>
</mat-card>