import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const maxAgeInMilliseconds = 30000;
@Injectable()
export class RequestCacheService {
  private cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams.trim();
    const cached = this.cache.get(url);
    const expired = Date.now() - maxAgeInMilliseconds;

    if (!cached) {
      return undefined;
    } else if (cached.lastRead < expired) {
      return undefined;
    } else {
      return cached.response;
    }
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams.trim();
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAgeInMilliseconds;
    this.cache.forEach((expiredEntry) => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }
}
