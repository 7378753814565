import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../authorization/services';
import { DisposalService } from '../disposal/services';
import { AbstractStyleAwareComponent } from '../shared/components';
import { GlobalErrorHandler } from '../shared/handlers';
import { GlobalVariableService, ThemeService } from '../shared/services';

@Component({
  selector: 'app-register-deposit',
  templateUrl: './register-deposit.component.html',
  styleUrls: ['./register-deposit.component.scss'],
})
export class RegisterDepositComponent extends AbstractStyleAwareComponent {
  numberOfDeposits: number = 1;

  public isProcessing: boolean = false;
  public isCancelling: boolean = false;

  constructor(
    private router: Router,
    private disposalService: DisposalService,
    private authorizationService: AuthorizationService,
    private globalErrorHandler: GlobalErrorHandler,
    globalVariableService: GlobalVariableService,
    themeService: ThemeService
  ) {
    super(globalVariableService, themeService)
  }

  async registerDeposit(): Promise<void> {
    let userId = this.globalVariableService.getUserId() as string;
    let userName = this.globalVariableService.getUserName() as string;
    let frontend = this.globalVariableService.getFrontend();
    let cardNumber = this.globalVariableService.getCardNumber() as string;
    try {
      let isAnonymousCard =
        this.authorizationService.isAnonymousCard(cardNumber);

      this.isProcessing = true;

      await this.disposalService.disposeOnCardAsync(
        frontend._vc_frontendid_value,
        cardNumber,
        userId,
        userName,
        isAnonymousCard
      );

      this.isProcessing = false;
      this.router.navigateByUrl('success', { replaceUrl: true });
    } catch (err) {
      this.isProcessing = false;
      this.globalErrorHandler.handleError(err);
    }
  }

  async cancelDeposit(): Promise<void> {
    let userId = this.globalVariableService.getUserId() as string;
    let userName = this.globalVariableService.getUserName() as string;
    let frontend = this.globalVariableService.getFrontend();
    let cardNumber = this.globalVariableService.getCardNumber() as string;

    try {
      let isAnonymousCard =
        this.authorizationService.isAnonymousCard(cardNumber);

      this.isCancelling = true;

      await this.disposalService.cancelDisposalAsync(
        frontend._vc_frontendid_value,
        cardNumber,
        userId,
        userName,
        isAnonymousCard
      );

      this.goToHome(true);
    } catch (err) {
      this.globalErrorHandler.handleError(err);
    }
  }

  goToHome(forceReload: boolean = false) {
    // We force a reload. Without the reload it seems the NFC reader goes into an unstable state.
    this.router.navigate(['/home', { forceReload: forceReload }], {
      replaceUrl: true,
    });
  }
}

