import { Component, OnInit } from '@angular/core';
import { GlobalVariableService, ThemeService } from '../services';

@Component({template: ''})
export class AbstractStyleAwareComponent<T = any> implements OnInit {
  constructor(
    protected globalVariableService: GlobalVariableService,
    protected themeService: ThemeService
  ) {}

  async ngOnInit(): Promise<void> {
    const businessUnitName = this.globalVariableService.getBusinessUnitName();

    if (businessUnitName) {
      await this.themeService.loadThemeAsync(businessUnitName);
    }
  }
}
