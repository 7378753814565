export class CardUtils {
  public static setEndianConvert(value: string): string {
    if (value.length % 2) {
      value = '0' + String(value);
    }
    var len = value.length / 2;
    var str = '';
    for (var i = 0; i < len; i++) {
      var count = len - i - 1;
      str += '' + value.substr(count * 2, 2);
    }
    str = str.toUpperCase();
    return str;
  }

  public static replaceColon(value: string): string {
    return value.replace(/:/g, '');
  }

  public static prependZeros(value: string, numOfCharacters: number): string {
    return String(value).padStart(numOfCharacters, '0');
  }

  public static convertLittleEndianToBigEndian(
    value: string,
    numOfCharacters: number
  ): string {
    value = this.replaceColon(value);
    if (value.match(/^0x[0-9a-fA-F]|[0-9a-fA-F]h$/i)) {
      value = value.replace(/^0x/i, '');
      value = value.replace(/h$/i, '');
    }

    value = this.setEndianConvert(value);
    value = this.prependZeros(value, numOfCharacters);
    return value;
  }
}
