import { NgModule } from "@angular/core";

import { DynamicsModule } from "../dynamics/dynamics.module";
import { DisposalService } from "./services";

@NgModule({
  declarations: [
  ],
  imports: [
    DynamicsModule
  ],
  providers: [
    DisposalService
  ],
})
export class DisposalModule {

}