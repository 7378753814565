

  <mat-card class="bottom-card full">
    <mat-card-header>
      <mat-card-title>User Information</mat-card-title>
    </mat-card-header>
    <mat-card-content>     
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Claim Column -->
            <ng-container matColumnDef="claim">
              <th mat-header-cell *matHeaderCellDef> Claim </th>
              <td mat-cell *matCellDef="let element"> {{element.claim}} </td>
            </ng-container>
          
            <!-- Value Column -->
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef> Value (v 1.007)</th>
              <td mat-cell *matCellDef="let element"> {{element.value}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           
          </table>
    </mat-card-content>
    <mat-card-actions>
        <button class="full" mat-flat-button color="primary" (click)="goToHome()"  (contextmenu)="goToHome()" disable-longpress>Home</button>
        <button class="full" mat-flat-button color="secondary" (click)="logout()" (contextmenu)="logout()" disable-longpress>Logout</button>
    </mat-card-actions>
  </mat-card>
