import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { FrontendService, LogdataService } from "./services";

@NgModule({
  imports: [
    HttpClientModule
  ],
  declarations: [
  ],
  providers: [
    FrontendService,
    LogdataService
  ],
})

export class DynamicsModule {
  
}