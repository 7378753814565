import { Injectable } from '@angular/core';

import { LogdataService } from 'src/app/dynamics/services';
import { CompletionCodes } from 'src/app/shared/enums/completionCodes';
import { LogdataRequestModel } from 'src/app/shared/models';
import { LoggingService } from 'src/app/shared/services';

@Injectable()
export class DisposalService {
  public constructor(
    private logdataService: LogdataService,
    private loggingService: LoggingService
  ) {}

  public async registerUnknownCardAsync(
    frontendId: string,
    cardId: string,
    userId: string,
    userName: string,
    anonimizeCard: boolean
  ): Promise<void> {
    let requestModel = await this.validateAndCreateRequestModel(
      frontendId,
      cardId,
      userId,
      userName,
      anonimizeCard,
      CompletionCodes.UnknownCard
    );

    return this.logdataService.insertLogdataAsync(requestModel);
  }

  public async disposeOnCardAsync(
    frontendId: string,
    cardId: string,
    userId: string,
    userName: string,
    anonimizeCard: boolean
  ): Promise<void> {
    let requestModel = await this.validateAndCreateRequestModel(
      frontendId,
      cardId,
      userId,
      userName,
      anonimizeCard,
      CompletionCodes.Disposal
    );

    return this.logdataService.insertLogdataAsync(requestModel);
  }

  public async cancelDisposalAsync(
    frontendId: string,
    cardId: string,
    userId: string,
    userName: string,
    anonimizeCard: boolean
  ): Promise<void> {
    let requestModel = await this.validateAndCreateRequestModel(
      frontendId,
      cardId,
      userId,
      userName,
      anonimizeCard,
      CompletionCodes.DisposalCanceled
    );

    return this.logdataService.insertLogdataAsync(requestModel);
  }

  private async validateAndCreateRequestModel(
    frontendId: string,
    cardId: string,
    userId: string,
    userName: string,
    anonimizeCard: boolean,
    completionCode: number
  ): Promise<LogdataRequestModel> {
    let errorMessage = '';

    if (!frontendId) {
      errorMessage += 'frontendId was undefined or empty \n';
    }

    if (!cardId) {
      errorMessage += 'cardId was undefined or empty \n';
    }

    if (!userId) {
      errorMessage += 'userId was undefined or empty \n';
    }

    if (!userName) {
      errorMessage += 'userName was undefined or empty \n';
    }

    if (errorMessage) {
      console.error(errorMessage);
      return Promise.reject(errorMessage);
    }

    var timestampLocal = new Date();
    var cardIdToUse = cardId;

    if (anonimizeCard) {
      console.log('Anonimizing card');
      this.loggingService.logTrace('Anonimizing card');
      cardIdToUse = '9999999999999999';
    }

    var requestModel: LogdataRequestModel = {
      TimestampLocal: timestampLocal,
      FrontendId: frontendId,
      CardInternalNumber: cardIdToUse,
      UserId: userId,
      UserName: userName,
      CompletionCode: completionCode,
    };

    return requestModel;
  }
}
