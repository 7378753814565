export enum CardAuthorization {
  NoAuthorization = 0,
  Deposit = 10,
  DepositAnonymous = 15,
  LockTest = 21,
  ScrollServiceMenu = 22,
  ActivateBluetoothService = 23,
  Communication = 33,
  ShowSystemInfoMenu = 34,
  ResetMeasurement = 41
}