import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/authorization/services';
import { DisposalService } from 'src/app/disposal/services';
import { AbstractStyleAwareComponent } from 'src/app/shared/components';
import { ErrorPageType } from 'src/app/shared/enums/errorPageTypes';
import { GlobalErrorHandler } from 'src/app/shared/handlers';
import {
  AudioService,
  GlobalVariableService,
  LoggingService,
  ThemeService,
} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { NfcService } from '../services';
import { CardUtils } from '../utils';

@Component({
  selector: 'app-read-nfc',
  templateUrl: './read-nfc.component.html',
  styleUrls: ['./read-nfc.component.scss'],
})
export class ReadNfcComponent
  extends AbstractStyleAwareComponent
  implements OnInit, OnDestroy
{
  public isRegisteringUnknownCard: boolean = false;

  private NUMBER_OF_CHARACTERS: number = 16;
  private dummyUid: string = '04:EC:6B:FA:51:53:80'; //Working dummy card
  // private dummyUid: string = '04:EC:6B:FA:51:53:81'; //Non-working dummy card

  private readonly DOCUMENT_VISIBILITY_CHANGE = 'visibilitychange';

  logLines: any = [];
  circleTitle: string = '';

  nfcTagReadSubscription: Subscription | undefined = undefined;
  nfcTagReadFailedSubscription: Subscription | undefined = undefined;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private audioService: AudioService,
    private authorizationService: AuthorizationService,
    private loggingService: LoggingService,
    private disposalService: DisposalService,
    private globalErrorHandler: GlobalErrorHandler,
    private nfcService: NfcService,
    globalVariableService: GlobalVariableService,
    themeService: ThemeService
  ) {
    super(globalVariableService, themeService);
  }

  async ngOnInit() {
    await super.ngOnInit();

    document.addEventListener(this.DOCUMENT_VISIBILITY_CHANGE, () => {
      if (document.hidden) {
        this.goToHome(true);
      }
    });

    this.nfcTagReadSubscription = this.nfcService.nfcTagRead.subscribe(
      (serialNumber: any) => {
        let internalNumber = CardUtils.convertLittleEndianToBigEndian(
          serialNumber,
          this.NUMBER_OF_CHARACTERS
        );

        this.log(`> Serial Number: ${serialNumber}`);
        this.log(`> Big Endian: ${internalNumber}`);

        this.validateCard(internalNumber);
      }
    );

    this.nfcTagReadFailedSubscription =
      this.nfcService.nfcTagReadFailed.subscribe((message: string) => {
        this.log(message);
      });

    try {
      this.setCircleTitle('PAGE_NFC.CIRCLE_WAITING_FOR_CARD');
      this.nfcService.startScanning();
    } catch (error) {
      console.error(error);
      this.loggingService.logException(error as Error);

      //Usually goes here when the NFC reader cannot be used on the hardware
      this.setCircleTitle('PAGE_NFC.CIRCLE_FAIL');

      if (this.isDebug()) {
        this.log(`Preparing dummy: ${this.dummyUid}`);
        this.log(
          `> Big Endian: ${CardUtils.convertLittleEndianToBigEndian(
            this.dummyUid,
            this.NUMBER_OF_CHARACTERS
          )}`
        );

        let internalNumber = CardUtils.convertLittleEndianToBigEndian(
          this.dummyUid,
          this.NUMBER_OF_CHARACTERS
        );
        this.validateCard(internalNumber);
      } else {
        this.router.navigateByUrl('error', { replaceUrl: true });
      }
    }
  }

  ngOnDestroy() {
    if (this.nfcTagReadSubscription) {
      this.nfcTagReadSubscription.unsubscribe();
      this.nfcTagReadSubscription = undefined;
    }

    if (this.nfcTagReadFailedSubscription) {
      this.nfcTagReadFailedSubscription.unsubscribe();
      this.nfcTagReadFailedSubscription = undefined;
    }

    this.nfcService.stopScanning();
  }

  isDebug(): boolean {
    return !environment.production;
  }

  private validateCard(number: string): void {
    this.circleTitle = this.translate.instant(
      'PAGE_NFC.CIRCLE_READING_FROM_CLOUD'
    );

    this.nfcService.stopScanning();

    this.audioService.playAudio('beep');

    try {
      const frontend = this.globalVariableService.getFrontend();

      this.authorizationService
        .canCardDisposeAsync(number, frontend._vc_frontendid_value)
        .then(async (canDispose) => {
          if (canDispose) {
            this.globalVariableService.setCardNumber(number);

            this.setCircleTitle('PAGE_NFC.CIRCLE_DONE');

            this.registerDeposit();
          } else {
            console.error(`Cannot deposit on card: ${number}`);
            this.loggingService.logTrace(`Cannot deposit on card: ${number}`);

            this.isRegisteringUnknownCard = true;
            await this.disposalService.registerUnknownCardAsync(
              frontend._vc_frontendid_value,
              number,
              this.globalVariableService.getUserId()!,
              this.globalVariableService.getUserName()!,
              this.authorizationService.isAnonymousCard(number)
            );
            this.isRegisteringUnknownCard = false;

            this.router.navigate(
              ['/error', { errorPageType: ErrorPageType.CardNotOnWhitelist, forceReload: true }],
              { replaceUrl: true }
            );
          }
        })
        .catch((err) => {
          this.nfcService.stopScanning();
          this.globalErrorHandler.handleError(err);
        });
    } catch (err) {
      this.nfcService.stopScanning();
      this.globalErrorHandler.handleError(err);
    }
  }

  public goToHome(forceReload: boolean = false): void {
    this.nfcService.stopScanning();

    // We force a reload. Without the reload it seems the NFC reader goes into an unstable state and doesn't read anything anymore.
    this.router.navigate(['/home', { forceReload: forceReload }], {
      replaceUrl: true,
    });
  }

  registerDeposit(): void {
    this.nfcService.stopScanning();
    this.router.navigateByUrl('register-deposit', { replaceUrl: true });
  }

  private log(line: string): void {
    this.logLines.push(line);
  }

  private setCircleTitle(key: string): void {
    this.translate.get(key).subscribe((text) => {
      this.circleTitle = text;
    });
  }
}
