import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { from, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../authorization/services';
import { ErrorPageType } from '../shared/enums/errorPageTypes';
import { LocalStorageKeys } from '../shared/enums/localStorageKeys';
import { GlobalErrorHandler } from '../shared/handlers';
import {
  GlobalVariableService,
  LoggingService,
  ThemeService,
} from '../shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isIframe = false;
  userIsLoggedIn = false;
  dataSource: any = [];
  displayedColumns: string[] = ['claim', 'value'];
  isLoggingIn = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private globalVariableService: GlobalVariableService,
    private authorizationService: AuthorizationService,
    private loggingService: LoggingService,
    private globalErrorHandler: GlobalErrorHandler,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        this.loggingService.logTrace(`${result}`);
        const payload = result.payload as AuthenticationResult;

        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        this.loggingService.logTrace(`${result}`);

        this.router.navigate(
          ['/error', { errorPageType: ErrorPageType.UnknownUserOrPassword }],
          { replaceUrl: true }
        );
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();

        this.getClaims(
          this.authService.instance.getActiveAccount()?.idTokenClaims
        );

        this.globalVariableService.setUserId(
          this.authService.instance.getActiveAccount()?.localAccountId
        );

        this.globalVariableService.setUserName(
          this.authService.instance.getActiveAccount()?.username
        );

        let userName = this.authService.instance.getActiveAccount()
          ?.username as string;

        if (userName) {
          from(this.authorizeWithDynamicsAsync(userName)).subscribe(
            (result) => {

              this.isLoggingIn = false;

              if (!result) {
                localStorage.removeItem(LocalStorageKeys.AccessToken);
                localStorage.removeItem(LocalStorageKeys.AccessTokenExpiryDate);
                this.globalVariableService.clearAllVariables();

                this.authService.logoutRedirect({
                  account: this.authService.instance.getActiveAccount(),
                  postLogoutRedirectUri:
                    '/#/error;errorPageType=40;redirectUri=/',
                });
              } else {
                this.redirectToHome();
              }
            }
          );
        }
      });
  }

  async authorizeWithDynamicsAsync(userName: string): Promise<boolean> {
    let isAuthorizedWithDynamics = false;
    this.isLoggingIn = true;

    try {
      let frontendResponse =
        await this.authorizationService.GetFrontendForUserAsync(userName);
      if (!frontendResponse) {
        isAuthorizedWithDynamics = false;
      }
      isAuthorizedWithDynamics = true;

      this.globalVariableService.setBusinessUnitName(
        frontendResponse.owningbusinessunit.name
      );

      // Force loading a theme when the user logs into Dynamics to override any cached items
      await this.themeService.loadThemeAsync(
        frontendResponse.owningbusinessunit.name,
        true
      );

      this.globalVariableService.setFrontend(frontendResponse);
      this.globalVariableService.setBusinessUnitId(
        frontendResponse._owningbusinessunit_value
      );

      await this.authorizationService.getWhitelistAsync(
        frontendResponse._vc_frontendid_value
      );
    } catch (err) {
      this.globalErrorHandler.handleErrorAndNavigate(err, false);
      isAuthorizedWithDynamics = false;
    } finally {
      this.globalVariableService.setAuthorizedWithDynamics(
        isAuthorizedWithDynamics
      );
      this.isLoggingIn = false;
      return Promise.resolve(isAuthorizedWithDynamics);
    }
  }

  redirectToHome() {
    if (this.userIsLoggedIn) {
      this.router.navigateByUrl('home', { replaceUrl: true });
    }
  }

  redirectToLogin() {
    this.router.navigateByUrl('/', { replaceUrl: true });
  }

  login() {
    this.isLoggingIn = true;

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        let req = {
          scopes: [environment.dynamics.url + '//user_impersonation'],
        };

        this.authService.loginRedirect(req);
      }
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.userIsLoggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    this.dataSource = [
      { id: 1, claim: 'Display Name', value: claims ? claims['name'] : null },
      {
        id: 2,
        claim: 'User Principal Name (UPN)',
        value: claims ? claims['preferred_username'] : null,
      },
      { id: 2, claim: 'OID', value: claims ? claims['oid'] : null },
    ];
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
