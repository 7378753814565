import { NgModule } from "@angular/core";

import { DynamicsModule } from "../dynamics/dynamics.module";

import { AuthorizationService } from "./services";

@NgModule({
  declarations: [
  ],
  imports: [
    DynamicsModule
  ],
  providers: [
    AuthorizationService
  ],
})
export class AuthorizationModule {
  
}