import { Component, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isIframe = false;
  loginDisplay = false;
  showWelcomeBackground = false;
  username = '';

  private readonly _destroying$ = new Subject<void>();

  title = this.translate.get('APP.TITLE');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    translate.setDefaultLang('nl');

    // Listen for route changes
    router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: { url: any }) => {
        this.setBackground(event.url);
      });
  }

  ngOnInit(): void {
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  getUsername(): string {
    var claims = this.authService.instance.getActiveAccount()?.idTokenClaims;

    if (claims == undefined) {
      return '';
    }

    return claims['name'] || '';
  }

  setBackground(url: string) {
    switch (url) {
      case '/':
        this.showWelcomeBackground = true;
        break;
      case '/login':
        this.showWelcomeBackground = true;
        break;
      default:
        this.showWelcomeBackground = false;
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout() {
    this.authService.logout();
  }

  goToUserInformation() {
    this.router.navigateByUrl('userinfo', { replaceUrl: true });
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
