import { Injectable } from '@angular/core';

enum AudioState {
  paused,
  playing,
  ended
}

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private soundFolder: string = '../../assets/sounds/';
  private audio!: HTMLAudioElement;

  private state: AudioState = AudioState.paused;

  constructor() { 
    console.log('audio service constructed')
  }

  private addEventListeners() {
    this.audio.addEventListener('playing', () => {
      this.onPlaying();
    });

    this.audio.addEventListener('pause', () => {
      this.onPause();
    });
  
    this.audio.addEventListener('ended', () => {
      this.onStopped();
    });
  }

  private onPlaying() {
    console.log('Audio now playing');
    this.state = AudioState.playing;
  }

  private onPause() {
    console.log('Audio now paused');
    this.state = AudioState.paused;
  }

  private onStopped() {
    console.log('Audio now stopped');
    this.state = AudioState.ended;
  }

  playAudio(sound: string) {  
    if(this.state == AudioState.playing) {
      console.log('Hold your horses already playing a tune')
      return;
    }      

    this.audio = new Audio(this.soundFolder + sound + '_sound.mp3');
    this.addEventListeners();
    this.audio.play();

    this.state = AudioState.playing;
  }
}
