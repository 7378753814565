import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbstractStyleAwareComponent } from 'src/app/shared/components';
import { ErrorPageType } from 'src/app/shared/enums/errorPageTypes';
import { GlobalVariableService, ThemeService } from 'src/app/shared/services';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent
  extends AbstractStyleAwareComponent
  implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    globalVariableService: GlobalVariableService,
    themeService: ThemeService
  ) {
    super(globalVariableService, themeService);
  }

  private errorPageType: ErrorPageType = ErrorPageType.Unknown;
  private redirectUri: string = 'home';
  private forceReload: boolean = false;

  pageInformation: any = {
    Title: '',
    Description: '',
    Button_Text: '',
  };

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.route.paramMap.subscribe((params) => {
      let errorType: any = params.get('errorPageType');
      let redirectUri: any = params.get('redirectUri');
      let forceReload: any = params.get('forceReload');

      if (errorType !== null) {
        this.loadErrorDescription(errorType);
      } else {
        this.errorMessageUnknown();
      }

      if (redirectUri !== null) {
        this.redirectUri = redirectUri;
      } else {
        this.redirectUri = 'home';
      }

      if (forceReload !== null) {
        this.forceReload = forceReload;
      } else {
        this.forceReload = false;
      }
    });
  }

  handleButton() {
    if (this.forceReload) {
      this.redirectUri = `${this.redirectUri};forceReload=${this.forceReload}`
    }

    this.router.navigateByUrl(this.redirectUri, { replaceUrl: true });
  }

  private loadErrorDescription(type: any) {
    let num: number = +type;

    if (num === undefined) {
      return;
    }

    switch (num) {
      case Number(ErrorPageType.CardNotOnWhitelist):
        this.errorMessageNotOnWhitelist();
        break;
      case Number(ErrorPageType.UnknownUserOrPassword):
        this.errorMessageUnknownUserOrPassword();
        break;
      case Number(ErrorPageType.NoConnection):
        this.errorMessageNoConnection();
        break;
      case Number(ErrorPageType.UnknownUserInDynamics):
        this.errorUserUnknownInDynamics();
        break;
      default:
        this.errorMessageUnknown();
        break;
    }
  }

  private errorMessageUnknown() {
    this.translate.get('PAGE_ERROR').subscribe(() => {
      this.pageInformation = {
        Title: this.translate.instant('PAGE_ERROR.TITLE'),
        Description: this.translate.instant('PAGE_ERROR.DESCRIPTION'),
        Button_Text: this.translate.instant('PAGE_ERROR.BUTTON_TRY_AGAIN'),
      };
    });
  }

  private errorMessageNotOnWhitelist() {
    this.translate.get('ERROR_WHITELIST').subscribe(() => {
      this.pageInformation = {
        Title: this.translate.instant('ERROR_WHITELIST.TITLE'),
        Description: this.translate.instant('ERROR_WHITELIST.DESCRIPTION'),
        Button_Text: this.translate.instant('ERROR_WHITELIST.BUTTON_TRY_AGAIN'),
      };
    });
  }

  private errorMessageUnknownUserOrPassword() {
    this.translate.get('ERROR_LOGIN').subscribe(() => {
      this.pageInformation = {
        Title: this.translate.instant('ERROR_LOGIN.TITLE'),
        Description: this.translate.instant('ERROR_LOGIN.DESCRIPTION'),
        Button_Text: this.translate.instant('ERROR_LOGIN.BUTTON_TRY_AGAIN'),
      };
    });
  }

  private errorMessageNoConnection() {
    this.translate.get('ERROR_CONNECTION').subscribe(() => {
      this.pageInformation = {
        Title: this.translate.instant('ERROR_CONNECTION.TITLE'),
        Description: this.translate.instant('ERROR_CONNECTION.DESCRIPTION'),
        Button_Text: this.translate.instant(
          'ERROR_CONNECTION.BUTTON_TRY_AGAIN'
        ),
      };
    });
  }

  private errorUserUnknownInDynamics() {
    this.translate.get('ERROR_LOGIN_DYNAMICS').subscribe(() => {
      this.pageInformation = {
        Title: this.translate.instant('ERROR_LOGIN_DYNAMICS.TITLE'),
        Description: this.translate.instant('ERROR_LOGIN_DYNAMICS.DESCRIPTION'),
        Button_Text: this.translate.instant(
          'ERROR_LOGIN_DYNAMICS.BUTTON_TRY_AGAIN'
        ),
      };
    });
  }
}
