import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageType } from '../enums';
import { LoggingService } from '../services';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private loggingService: LoggingService, private router: Router) {
    super();
  }

  handleError(error: any) {
    this.handleErrorAndNavigate(error, true);
  }

  handleErrorAndNavigate(error: any, navigate: boolean = true, errorPageType: ErrorPageType = ErrorPageType.Unknown): void {
    console.error(error);
    this.loggingService.logException(error);

    if (error instanceof HttpErrorResponse) {
      const httpError = error as HttpErrorResponse;
      if (httpError.status === 504) {
        if (navigate) {
          this.navigateToErrorPage(ErrorPageType.NoConnection);
        }
        return;
      }
    }
    if (navigate) {
      this.navigateToErrorPage(errorPageType);
    }
    return;
  }

  private navigateToErrorPage(
    errorPageType: ErrorPageType | undefined = undefined
  ) {
    if (errorPageType) {
      this.router.navigate(['/error', { errorPageType: errorPageType }], {
        replaceUrl: true,
      });
    } else {
      this.router.navigateByUrl('error', { replaceUrl: true });
    }
  }
}
