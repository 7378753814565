import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { SilentRequest } from "@azure/msal-browser";

import { environment } from "src/environments/environment";

@Injectable() 
export class FrontendService {

  private readonly BASE_URL = environment.dynamicsBaseUrl;

  constructor(private http: HttpClient, private msalService: MsalService) { }

  public getWhitelistAsync(frontendId: string): Promise<any> {
    let url = this.BASE_URL + "api/frontend/whitelist";

    let headers = new HttpHeaders().set('Accept', 'application/json');
    let params = new HttpParams().set('frontendId', frontendId);

    return this.http.get<any>(url, { headers, params }).toPromise();
  }

  public getWhitelistStatusAsync(frontendId: string): Promise<any> {
    let url = this.BASE_URL + "api/frontend/whitelist/status";

    let headers = new HttpHeaders().set('Accept', 'application/json');
    let params = new HttpParams().set('frontendId', frontendId);

    return this.http.get<any>(url, { headers, params }).toPromise();
  }

  public confirmWhitelistAsync(frontendId: string, whitelistId: string, whitelistTimestamp: Date): Promise<any> {
    let url = this.BASE_URL + "api/frontend/whitelist/confirm";

    let headers = new HttpHeaders().set('Accept', 'application/json');
    let body = {
      "FrontendId": frontendId,
      "WhitelistId": whitelistId,
      "WhitelistTimestamp": whitelistTimestamp
    };

    return this.http.post<any>(url, body, { headers }).toPromise();
  }

  public async getFrontendForUserAsync(userEmail: string): Promise<any> {
    let req: SilentRequest = {
      scopes: [environment.dynamics.url + '//user_impersonation']
    };

    let silentTokenResponse =
      await this.msalService.instance.acquireTokenSilent(req);

    let url = `${environment.dynamics.url}/api/data/v9.0/vc_frontenduserses?$select=_vc_frontendid_value,_owningbusinessunit_value,vc_userprimaryemail&$expand=owningbusinessunit($select=name)&$filter=(vc_userprimaryemail eq '${userEmail}' and statecode eq 0 and vc_frontendid/statecode eq 0) and (vc_frontendid/vc_frontendid ne null)&$top=1`

    const headerDict = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      Authorization: silentTokenResponse.accessToken,
    };

    let frontendResponse = await this.http
      .get<any>(url, {
        headers: new HttpHeaders(headerDict),
      })
      .toPromise();

    if (frontendResponse && frontendResponse.value.length > 0) {
      return frontendResponse.value[0];
    } else {
      return Promise.reject(
        `Could not find frontend for user: "${userEmail}"`
      );
    }
  }
}