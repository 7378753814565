import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { ReadNfcComponent } from './nfc/read-nfc/read-nfc.component';
import { RegisterDepositComponent } from './register-deposit/register-deposit.component';
import { ScanIntroductionComponent } from './scan-introduction/scan-introduction.component';
import { DynamicsAuthorizedGuard } from './shared/guards/dynamics-authorized.guard';
import { SharedModule } from './shared/shared.module';
import { ErrorComponent } from './system/error/error.component';
import { SuccessComponent } from './system/success/success.component';
import { UserInformationComponent } from './user-information/user-information.component';

const routes: Routes = [
  { 
    path: 'login', 
    component: LoginComponent
  },
  {
    path: 'home',
    component: ScanIntroductionComponent,
    canActivate: [MsalGuard, DynamicsAuthorizedGuard]
  },
  {
    path: 'listen',
    component: ReadNfcComponent,
    canActivate: [MsalGuard, DynamicsAuthorizedGuard]
  },
  {
    path: 'register-deposit',
    component: RegisterDepositComponent,
    canActivate: [MsalGuard, DynamicsAuthorizedGuard]
  },
  {
    path: 'success',
    component: SuccessComponent,
    canActivate: [MsalGuard, DynamicsAuthorizedGuard]
  },
  { 
    path: 'error', 
    component: ErrorComponent
  },
  /* For debugging purposes */
  {
    path: 'userinfo',
    component: UserInformationComponent,
    canActivate: [MsalGuard]
  },

  /* AZURE AD */
  {
    path: 'state',
    component: LoginComponent
  },
  {
    path: 'code',
    component: LoginComponent
  },
  { 
    path: '', 
    component: LoginComponent
  },
  { 
    path: '**', 
    component: ErrorComponent
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
    }),
    SharedModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
