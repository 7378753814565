<div class="circle-container">

    <div class="circle pulse">
        <div class="ring"></div>
        <div class="description">
            <h3>{{ circleTitle }}</h3>
        </div>
    </div>
</div>

<mat-card class="bottom-card full">
    <mat-card-header>
        <mat-card-title>{{ 'PAGE_NFC.TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>
            {{ 'PAGE_NFC.DESCRIPTION' | translate }}
        </p>

        <div id="debugContainer" *ngIf="isDebug()">
            <ul [hidden]="logLines.length == 0">
                <li><strong>Debug:</strong></li>
                <li *ngFor="let item of logLines">{{ item }}</li>
            </ul>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-flat-button [disabled]="isRegisteringUnknownCard" class="full" color="secondary" (click)="goToHome(true)" (contextmenu)="goToHome(true)" disable-longpress>{{ 'PAGE_NFC.BUTTON_CANCEL' | translate }}</button>
    </mat-card-actions>
</mat-card>