import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WarnForPermissionDialogComponent } from '../dialogs/warn-for-permission-dialog/warn-for-permission-dialog.component';
import { AbstractStyleAwareComponent } from '../shared/components';
import {
  GlobalVariableService,
  LoggingService,
  ThemeService,
} from '../shared/services';

@Component({
  selector: 'app-scan-introduction',
  templateUrl: './scan-introduction.component.html',
  styleUrls: ['./scan-introduction.component.scss'],
})
export class ScanIntroductionComponent
  extends AbstractStyleAwareComponent
  implements OnInit {

  public isLoading = false;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private router: Router,
    private loggingService: LoggingService,
    private route: ActivatedRoute,
    globalVarsService: GlobalVariableService,
    themeService: ThemeService
  ) {
    super(globalVarsService, themeService);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.route.paramMap.subscribe((params) => {
      let forceReload: any = params.get('forceReload');

      if (forceReload !== null && forceReload === 'true') {
        this.isLoading = true;

        setTimeout(() => {
          window.location.hash = '#/home'; // Clear out the forceReload param to get out of a reload loop
          window.location.reload();
        }, 500)
      }
    });
  }

  warnForPermission(): void {
    console.log('open dialog');
    this.loggingService.logTrace('open dialog');

    this.dialog.open(WarnForPermissionDialogComponent);
  }

  navigateToNfc(): void {
    this.router.navigateByUrl('listen', { replaceUrl: true });
  }
}
