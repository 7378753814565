import { Injectable } from '@angular/core';

import { FrontendService } from 'src/app/dynamics/services';
import { LoggingService } from 'src/app/shared/services';
import { CardAuthorization } from './card-authorization.enum';
import { WhitelistStatus } from './whitelist-status.enum';

@Injectable()
export class AuthorizationService {
  private cardList: any[] = [];
  private whitelistId: any;
  private whitelistTimestamp: Date = new Date();

  public constructor(
    private frontendService: FrontendService,
    private loggingService: LoggingService
  ) {}

  public async getWhitelistAsync(frontendId: string): Promise<any[]> {
    if (!frontendId || frontendId === '') {
      const errorMessage = 'frontendId was empty or undefined';

      console.error(errorMessage);
      return Promise.reject(errorMessage);
    }

    if (this.cardList.length === 0) {
      await this.getAndConfirmWhitelistAsync(frontendId);
    } else {
      const statusReponse = await this.frontendService.getWhitelistStatusAsync(
        frontendId
      );

      if (statusReponse !== WhitelistStatus.Confirmed) {
        await this.getAndConfirmWhitelistAsync(frontendId);
      }
    }

    return this.cardList;
  }

  private async getAndConfirmWhitelistAsync(frontendId: string) {
    if (!frontendId || frontendId === '') {
      const errorMessage = 'frontendId was empty or undefined';

      console.error(errorMessage);
      return Promise.reject(errorMessage);
    }

    const whitelistResponse = await this.frontendService.getWhitelistAsync(
      frontendId
    );

    if (whitelistResponse === null || !whitelistResponse) {
      const errorMessage = 'whitelistReponse was null or undefined';

      console.error(errorMessage);
      return Promise.reject(errorMessage);
    }

    this.cardList = whitelistResponse.CardList;
    this.whitelistId = whitelistResponse.WhitelistId;
    this.whitelistTimestamp = whitelistResponse.WhitelistTimestamp;

    return await this.frontendService.confirmWhitelistAsync(
      frontendId,
      this.whitelistId,
      this.whitelistTimestamp
    );
  }

  public async canCardDisposeAsync(
    cardId: string,
    frontendId: string
  ): Promise<boolean> {
    if (!cardId || cardId === '') {
      const errorMessage = 'cardId was empty or undefined';

      console.error(errorMessage);
      return Promise.reject(errorMessage);
    }

    const whitelistStatus = await this.frontendService.getWhitelistStatusAsync(
      frontendId
    );

    if (
      this.cardList.length == 0 ||
      whitelistStatus !== WhitelistStatus.Confirmed
    ) {
      await this.getAndConfirmWhitelistAsync(frontendId);
    }

    for (let i = 0; i < this.cardList.length; i++) {
      let cardListEntry = this.cardList[i];

      if (
        cardListEntry.InternalNumber === cardId &&
        (cardListEntry.Authorization === CardAuthorization.Deposit ||
          cardListEntry === CardAuthorization.DepositAnonymous)
      ) {
        console.log(`cardId ${cardId} is on whitelist`);
        this.loggingService.logTrace(`cardId ${cardId} is on whitelist`);
        return true;
      }
    }

    console.log(`cardId ${cardId} is not on the whitelist`);
    this.loggingService.logTrace(`cardId ${cardId} is not on the whitelist`);
    return false;
  }

  public async GetFrontendForUserAsync(email: string): Promise<any> {
    if (!email || email === '') {
      const errorMessage = 'email was empty or undefined';

      console.error(errorMessage);
      return Promise.reject(errorMessage);
    }

    return this.frontendService.getFrontendForUserAsync(email);
  }

  public isAnonymousCard(cardId: string): boolean {
    if (!cardId || cardId === '') {
      const errorMessage = 'cardId was empty or undefined';

      console.error(errorMessage);
      throw errorMessage;
    }

    for (let i = 0; i < this.cardList.length; i++) {
      if (this.cardList[i].InternalNumber === cardId) {
        return (
          this.cardList[i].Authorization === CardAuthorization.DepositAnonymous
        );
      }
    }
    return false;
  }
}
