export enum LocalStorageKeys {
  AccessToken = 'dynamics_accesstoken',
  AccessTokenExpiryDate = 'dynamics_accesstoken_expirydate',

  Frontend = 'frontend',
  UserId = 'userId',
  UserName = 'userName',
  CardNumber = 'cardNumber',
  BusinessUnitId = 'businessUnitId',
  IsAuthorizedWithDynamics = 'isAuthorizedWithDynamics',
  BusinessUnitName = 'businessUnitName'
}
