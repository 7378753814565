import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AbstractStyleAwareComponent } from '../shared/components';
import { LocalStorageKeys } from '../shared/enums/localStorageKeys';
import { GlobalVariableService, ThemeService } from '../shared/services';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent
  extends AbstractStyleAwareComponent
  implements OnInit
{
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any = [];

  constructor(
    private authService: MsalService,
    private router: Router,
    globalVariableService: GlobalVariableService,
    themeService: ThemeService
  ) {
    super(globalVariableService, themeService);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.getClaims();
  }

  getClaims() {
    var claims = this.authService.instance.getActiveAccount()?.idTokenClaims;
    this.dataSource = [
      { id: 1, claim: 'Display Name', value: claims ? claims['name'] : null },
      {
        id: 2,
        claim: 'User Principal Name (UPN)',
        value: claims ? claims['preferred_username'] : null,
      },
      { id: 2, claim: 'OID', value: claims ? claims['oid'] : null },
    ];
  }

  goToHome() {
    this.router.navigateByUrl('home', { replaceUrl: true });
  }

  logout() {
    localStorage.removeItem(LocalStorageKeys.AccessToken);
    localStorage.removeItem(LocalStorageKeys.AccessTokenExpiryDate);
    this.globalVariableService.clearAllVariables();

    this.authService.logout();
  }
}
