import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpResponse,
  HttpInterceptor,
  HttpHandler,
} from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { RequestCacheService } from '../services';
import { environment } from 'src/environments/environment';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (
      !req.url
        .toLowerCase()
        .includes(environment.dynamicsBaseUrl.toLowerCase()) ||
      req.url.toLowerCase().includes('token') ||
      req.url.toLowerCase().includes('api/data')
    ) {
      //Skip if the url isn't for dynamics, is the token or if this is OData request
      return next.handle(req);
    }

    // Only want to cache 'GET' calls
    if (req.method === "GET") {
      const cachedResponse = this.cache.get(req);

      if (cachedResponse) {
        return of(cachedResponse);
      } else {
        return this.sendRequestAndCacheResponse(req, next, this.cache);
      }
    } else {
      return next.handle(req);
    }
  }

  sendRequestAndCacheResponse(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCacheService
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
}
