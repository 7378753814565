<div class="logo">
  <img src="../../assets/img/vconsyst/logo_vconsyst.png" alt="VConsyst company logo">
</div>

<mat-card class="bottom-card">
  <mat-card-header>
    <mat-card-title class="center">{{ 'PAGE_LOGIN.TITLE' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    {{ 'PAGE_LOGIN.DESCRIPTION' | translate }}
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button class="full" color="primary" [class.button-spinner]="isLoggingIn" [disabled]="isLoggingIn" (click)="login()" (contextmenu)="login()" disable-longpress>
      {{ 'PAGE_LOGIN.BUTTON_LOGIN' | translate }}
    </button>    
  </mat-card-actions>
  <mat-card-actions>
    <p>{{ 'PAGE_LOGIN.LOST_PASSWORD' | translate }}</p>
  </mat-card-actions>
</mat-card>