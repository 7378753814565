<div class="circle-container">
  <div class="circle green" (click)="registerDeposit()">
      <div class="description">
        <span *ngIf="!isProcessing">
          <h3>{{ numberOfDeposits }} {{ 'PAGE_REGISTER_DEPOSIT.WASTEBAG' | translate }}</h3>
        </span>
        <span *ngIf="isProcessing">
          <mat-spinner class="mat-spinner-color" [diameter]="150" [strokeWidth]="10"></mat-spinner>
        </span>
      </div>
  </div>
</div>

<mat-card class="bottom-card full">
    <mat-card-header>
      <mat-card-title>{{ 'PAGE_REGISTER_DEPOSIT.TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>     
      <p>
        {{ 'PAGE_REGISTER_DEPOSIT.INTRO' | translate }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button [disabled]="isProcessing || isCancelling" class="hug" mat-flat-button color="secondary" (click)="cancelDeposit()" (contextmenu)="cancelDeposit()" disable-longpress>{{ 'PAGE_REGISTER_DEPOSIT.BUTTON_CANCEL' | translate }}</button>
      <button [disabled]="isProcessing || isCancelling" class="hug" mat-flat-button color="primary" (click)="registerDeposit()" (contextmenu)="registerDeposit()" disable-longpress>{{ 'PAGE_REGISTER_DEPOSIT.BUTTON_DEPOSIT' | translate }}</button>
    </mat-card-actions>
  </mat-card>