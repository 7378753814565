import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[disable-longpress]'
})
export class DisableLongpressDirective {

  constructor() { }

  @HostListener('contextmenu', ['$event']) onContextMenu (e: any) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
}