import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../enums';

@Injectable()
export class GlobalVariableService {
  public setFrontend(frontend: any) {
    this.setValueIntoStorage(LocalStorageKeys.Frontend, frontend);
  }

  public getFrontend(): any {
    return this.getValueOrUndefinedFromStorage(LocalStorageKeys.Frontend);
  }

  public setUserId(userId: string | undefined) {
    this.setValueIntoStorage(LocalStorageKeys.UserId, userId);
  }

  public getUserId(): string | null {
    return this.getValueOrUndefinedFromStorage(LocalStorageKeys.UserId);
  }

  public setUserName(userName: string | undefined) {
    this.setValueIntoStorage(LocalStorageKeys.UserName, userName);
  }

  public getUserName(): string | null {
    return this.getValueOrUndefinedFromStorage(LocalStorageKeys.UserName);
  }

  public setCardNumber(cardNumber: string | undefined) {
    this.setValueIntoStorage(LocalStorageKeys.CardNumber, cardNumber);
  }

  public getCardNumber(): string | null {
    return this.getValueOrUndefinedFromStorage(LocalStorageKeys.CardNumber);
  }

  public setBusinessUnitId(businessUnitId: string | undefined) {
    this.setValueIntoStorage(LocalStorageKeys.BusinessUnitId, businessUnitId);
  }

  public getBusinessUnitId(): string | undefined {
    return this.getValueOrUndefinedFromStorage(LocalStorageKeys.BusinessUnitId);
  }

  public setAuthorizedWithDynamics(isAuthorized: boolean) {
    this.setValueIntoStorage(LocalStorageKeys.IsAuthorizedWithDynamics, isAuthorized);
  }

  public getIsAuthorizedWithDynamics(): boolean {
    var valueFromStorage = this.getValueOrUndefinedFromStorage(LocalStorageKeys.IsAuthorizedWithDynamics);
    if (valueFromStorage !== undefined) {
      return valueFromStorage;
    }
    return false;
  }

  public setBusinessUnitName(businessUnitName: string | undefined) {
    this.setValueIntoStorage(LocalStorageKeys.BusinessUnitName, businessUnitName);
  }

  public getBusinessUnitName(): string | undefined {
    return this.getValueOrUndefinedFromStorage(LocalStorageKeys.BusinessUnitName);
  }

  public clearAllVariables() {
    localStorage.removeItem(LocalStorageKeys.Frontend),
    localStorage.removeItem(LocalStorageKeys.UserId),
    localStorage.removeItem(LocalStorageKeys.UserName),
    localStorage.removeItem(LocalStorageKeys.CardNumber),
    localStorage.removeItem(LocalStorageKeys.BusinessUnitId),
    localStorage.removeItem(LocalStorageKeys.IsAuthorizedWithDynamics),
    localStorage.removeItem(LocalStorageKeys.BusinessUnitName)
  }

  private getValueOrUndefinedFromStorage(key: string): any | undefined {
    var valueFromStorage = localStorage.getItem(key);

    if (valueFromStorage) {
      return JSON.parse(valueFromStorage);
    }
    return undefined;
  }

  private setValueIntoStorage(key: string, value: any) {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}
