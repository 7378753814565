<div class="canvas" [ngClass]="{ 'corporate-bg': showWelcomeBackground }">

  <!-- Toolbar -->
  <mat-toolbar *ngIf="loginDisplay">
    <span (click)="goToUserInformation()" (contextmenu)="goToUserInformation()" disable-longpress>{{ getUsername() }}</span>
    <span class="logo"></span>
  </mat-toolbar>
  <div class="content" role="main">
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
</div>