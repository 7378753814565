import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ThemeModel } from '../models';
import { LoggingService } from './logging.service';

import { environment } from 'src/environments/environment';

@Injectable()
export class ThemeService {
  private retrievedTheme: ThemeModel | undefined = undefined;
  private isThemeResolved: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private loggingService: LoggingService
  ) {}

  async loadThemeAsync(
    themeName: string | undefined,
    forceLoad: boolean = false
  ): Promise<boolean> {
    if (!themeName) return false;

    themeName = themeName.toLocaleLowerCase().replace(' ', '_');

    const themeToLoadUrl = `${environment.themeBaseUrl}${themeName}/theme.json`;
    const iconToLoadUrl = `${environment.themeBaseUrl}${themeName}/logo.png`;

    try {
      // We do not need to retrieve the theme because we know it already doesn't exists
      if (this.isThemeResolved && !this.retrievedTheme && !forceLoad) {
        return false;
      }

      const result = await this.retrieveThemeAsync(themeToLoadUrl);

      if (result) {
        this.setStyleProperty(result.primaryColor, '--primary-color');
        this.setStyleProperty(result.secondaryColor, '--secondary-color');
        this.setStyleProperty(result.tertiaryColor, '--tertiary-color');
        this.setStyleProperty(result.background, '--app-background');
        this.setStyleProperty(
          result.backgroundOverlay,
          '--app-background-overlay',
          true
        );
        this.setStyleProperty(`url(${iconToLoadUrl})`, '--app-background-logo');
      }
    } catch {
      this.loggingService.logTrace(
        `Theme for '${themeName}' could not be loaded, reverting to default theme`
      );
      return false;
    }

    return true;
  }

  private async retrieveThemeAsync(themeToLoad: string): Promise<ThemeModel> {
    if (!this.retrievedTheme) {
      try {
        const theme = await this.httpClient
          .get<ThemeModel>(themeToLoad)
          .toPromise();

        this.retrievedTheme = theme;
        this.isThemeResolved = true;

        return theme;
      } catch (err) {
        this.retrievedTheme = undefined;
        this.isThemeResolved = true;

        throw err;
      }
    }

    return this.retrievedTheme;
  }

  private setStyleProperty(
    valueToSet: string | undefined,
    labelToSet: string,
    clearStyleIfEmpty = false
  ) {
    if (valueToSet) {
      document.documentElement.style.setProperty(labelToSet, valueToSet);
    } else if (clearStyleIfEmpty) {
      document.documentElement.style.setProperty(labelToSet, ' '); // We set this to a space because we do not want to use the default value, instead we want it ignored
    }
  }
}
