import { EventEmitter, Injectable } from '@angular/core';
import { LoggingService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class NfcService {
  private readonly NFC_EVENT_READINGERROR = 'readingerror';
  private readonly NFC_EVENT_READING = 'reading';

  public nfcTagRead: EventEmitter<string> = new EventEmitter();
  public nfcTagReadFailed: EventEmitter<string> = new EventEmitter();

  private ndef: NDEFReader | null = null;

  constructor(private loggingService: LoggingService) {}

  public startScanning(): void {
    //Check if the 'NDEFReader' is supported by the browser
    if ('NDEFReader' in window) {
      this.ndef = this.setupNdefReader();

      if (this.ndef !== null) {
        this.ndef.scan();
      }
    } else {
      const errorMessage = 'NDEFReader cannot be used in this browser';
      this.loggingService.logTrace(errorMessage);
      throw errorMessage;
    }
  }

  public stopScanning(): void {
    this.unregisterNFCEventListeners(this.ndef);
  }

  private setupNdefReader(): NDEFReader {
    const ndef = new NDEFReader();

    this.registerNFCEventListeners(ndef);

    return ndef;
  }

  private registerNFCEventListeners(ndef: NDEFReader): void {
    ndef.addEventListener(this.NFC_EVENT_READINGERROR, () => {
      this.loggingService.logTrace('Could not read NFC tag');
      this.nfcTagReadFailed.emit('Could not read NFC tag');
    });

    ndef.addEventListener(
      this.NFC_EVENT_READING,
      ({ message, serialNumber }: any) => {
        this.loggingService.logTrace(
          `Succesfully read NFC tag - serialNumber: ${serialNumber} - records: ${message.records.length}`
        );
        this.nfcTagRead.emit(serialNumber);
      }
    );
  }

  private unregisterNFCEventListeners(ndef: NDEFReader | null): void {
    if (ndef !== null) {
      ndef?.removeAllListeners?.(this.NFC_EVENT_READING);
      ndef?.removeAllListeners?.(this.NFC_EVENT_READINGERROR);
    }
  }
}
