import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LocalStorageKeys } from '../enums';
import { GlobalVariableService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly cloudconnector_api_type = 3;

  constructor(
    private httpClient: HttpClient,
    private msalService: MsalService,
    private globalVariableService: GlobalVariableService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !req.url
        .toLowerCase()
        .includes(environment.dynamicsBaseUrl.toLowerCase()) ||
      req.url.toLowerCase().includes('token') ||
      req.url.toLowerCase().includes('api/data')
    ) {
      //Skip if the url isn't for dynamics, is the token or if this is OData request
      return next.handle(req);
    }

    return from(
      this.GetDynamicsTokenAsync(this.globalVariableService.getBusinessUnitId())
    ).pipe(
      concatMap((token) => {
        let headers = {
          Authorization: `Bearer ${token}`,
        };

        const clonedRequest = req.clone({
          setHeaders: headers,
        });

        return next.handle(clonedRequest);
      })
    );
  }

  private async GetDynamicsTokenAsync(
    businessUnitId: string | undefined
  ): Promise<string> {
    const localStorageAccessToken = localStorage.getItem(
      LocalStorageKeys.AccessToken
    );
    const localStorageAccessTokenExpiresAt = localStorage.getItem(
      LocalStorageKeys.AccessTokenExpiryDate
    );

    if (
      localStorageAccessToken &&
      localStorageAccessToken !== null &&
      localStorageAccessTokenExpiresAt &&
      localStorageAccessTokenExpiresAt !== null
    ) {
      const expiryDate = new Date(localStorageAccessTokenExpiresAt);
      const currentDateTime = new Date();

      if (currentDateTime < expiryDate) {
        return localStorageAccessToken;
      }
    }

    let req: SilentRequest = {
      scopes: [environment.dynamics.url + '//user_impersonation'],
    };

    let url = `${environment.dynamics.url}/api/data/v9.0/vc_apitokens?$filter=_vc_businessunit_value eq ${businessUnitId} and vc_apitype eq ${this.cloudconnector_api_type}`;

    let silentTokenResponse =
      await this.msalService.instance.acquireTokenSilent(req);

    const headerDict = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      Authorization: silentTokenResponse.accessToken,
    };

    let tokenResponse = await this.httpClient
      .get<any>(url, {
        headers: new HttpHeaders(headerDict),
      })
      .toPromise();

    if (tokenResponse && tokenResponse.value.length > 0) {
      localStorage.setItem(
        LocalStorageKeys.AccessToken,
        tokenResponse.value[0].vc_accesstoken
      );

      var accessTokenExpirationDateTime = new Date(
        tokenResponse.value[0].vc_accessissuedon
      );

      accessTokenExpirationDateTime.setSeconds(
        accessTokenExpirationDateTime.getSeconds() +
          tokenResponse.value[0].vc_accessexpiresin
      );

      localStorage.setItem(
        LocalStorageKeys.AccessTokenExpiryDate,
        accessTokenExpirationDateTime.toISOString()
      );

      return tokenResponse.value[0].vc_accesstoken as string;
    } else {
      throw `No access token found for BU ${businessUnitId} and type ${this.cloudconnector_api_type}`;
    }
  }
}
