import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { GlobalVariableService } from "../services";

@Injectable()
export class DynamicsAuthorizedGuard implements CanActivate {
  constructor(private globalVarService: GlobalVariableService) {} 

  canActivate() {
    return this.globalVarService.getIsAuthorizedWithDynamics();
  }
}