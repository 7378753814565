import { NgModule } from '@angular/core';

import { DynamicsAuthorizedGuard } from './guards';
import { GlobalErrorHandler } from './handlers';
import {
  AudioService,
  GlobalVariableService,
  LoggingService,
  RequestCacheService,
  ThemeService
} from './services';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    GlobalVariableService,
    LoggingService,
    RequestCacheService,
    GlobalErrorHandler,
    DynamicsAuthorizedGuard,
    AudioService,
    ThemeService
  ],
})
export class SharedModule {}
