<div *ngIf="isLoading" class="scanintroduction-loader">
  <div class="center-spinner">
    <mat-spinner
      class="mat-spinner-color"
      [diameter]="150"
      [strokeWidth]="10"
    ></mat-spinner>
  </div>
  <div>
    <h3> {{ "HOME.LOADING" | translate }}</h3>
  </div>
</div>

<span *ngIf="!isLoading">
  <div class="instructions">
    <ul>
      <li class="active">
        <span>1</span> {{ "HOME.INTRO_LINE_1" | translate }}
      </li>
      <li class="active">
        <span>2</span> {{ "HOME.INTRO_LINE_2" | translate }}
      </li>
      <li class="active">
        <span>3</span> {{ "HOME.INTRO_LINE_3" | translate }}
      </li>
    </ul>
  </div>

  <mat-card class="bottom-card full">
    <mat-card-header>
      <mat-card-title>{{ "HOME.TITLE" | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        {{ "HOME.INTRO" | translate }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button
        class="full"
        mat-flat-button
        color="primary"
        (click)="navigateToNfc()"
        (contextmenu)="navigateToNfc()"
        disable-longpress
      >
        {{ "HOME.NEXT_BUTTON" | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</span>
