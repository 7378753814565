import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LogdataRequestModel } from "src/app/shared/models";

import { environment } from "src/environments/environment";

@Injectable() 
export class LogdataService {

  private readonly BASE_URL = environment.dynamicsBaseUrl;

  constructor(private http: HttpClient) {}

  public insertLogdataAsync(logdataRequestModel: LogdataRequestModel) : Promise<void> {
    let url = this.BASE_URL + "api/logdata";

    let headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.post<void>(url, logdataRequestModel, { headers }).toPromise();
  }
}