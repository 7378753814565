<div class="circle-container">
  <div (click)="goToHome()" (contextmenu)="goToHome()" class="circle green success" disable-longpress>
      <div class="description">
      </div>
  </div>
</div>

<mat-card class="bottom-card full">
    <mat-card-header>
      <mat-card-title>{{ 'PAGE_SUCCESS.TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>     
      <p>
        {{ 'PAGE_SUCCESS.DESCRIPTION' | translate }}   
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button class="full" mat-flat-button color="primary" (click)="goToHome(true)" (contextmenu)="goToHome(true)" disable-longpress>{{ 'PAGE_SUCCESS.BUTTON_NEW' | translate }}</button>
    </mat-card-actions>
  </mat-card>